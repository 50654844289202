import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "../components/header"
import Section from "../components/section"
import TwmButton from "../components/twm-button"

const WhoAreWePage = props => (
  <Layout firmName={props.pageContext.firm} firmWeb={props.pageContext.web}>
    <SEO title="Who Are We" />
    <BackgroundImage
      fluid={props.data.whoAreWeImage.childImageSharp.fluid}
      alt=""
    >
      <Header headerText="Who Are We"></Header>
    </BackgroundImage>

    <Section>
      <h2>
        You work hard for your money. It is important that your money works hard
        for you.
      </h2>
      <p>
        Our team of experienced <strong>independent Financial Planners</strong>{" "}
        will work alongside your accountant to assist you with all your
        financial planning needs.
      </p>
      <p>
        As your accountant works with you to ensure your tax affairs are
        optimal. When it comes to your personal wealth there is far more to it
        than just tax planning. This is why we believe that in the same way you
        receive expert advice relating to your tax affairs and business
        management, you should ensure you receive expert financial planning
        advice from a qualified financial planner.
      </p>
      <p>
        We will work with you to identify, quantify and bring to life your life
        goals, and assess any strengths or weaknesses in your current financial
        plan.
      </p>
      <p>
        As well as establishing your financial plan we will advise you on making
        sure your loved ones, beneficiaries or your business is financially
        protected in the event of your death or serious illness to you or key
        people with your business.
      </p>
      <p>
        Finally, we will ensure that together with the tax planning your
        accountant is providing, we will ensure that you are making use of
        allowances, exemptions and tax-planning opportunities to minimise your
        taxes.<sup>*</sup>
      </p>
      <p>
        For more information or to arrange an initial conversation with an
        adviser please contact us or email info@tagfp.co.uk and one of our
        advisers will be in contact.
      </p>
      <p>
        <small>
          * Tax and estate planning services are not regulated by the Financial
          Conduct Authority
        </small>
      </p>

      <TwmButton toRef={props.pageContext.firm + "/contact-us"}>
        Contact Us
      </TwmButton>
    </Section>
  </Layout>
)

export default WhoAreWePage

export const pageQuery = graphql`
  query {
    whoAreWeImage: file(relativePath: { eq: "who-are-we.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
